$sidebar-width: 20%;
$sidebar-collapsed-width: 100px;



$icon-bg-color:transparent;

html[data-theme="dark"] {
  .pro-sidebar-inner {
    background: #1B1B1B;
  }

  .pro-item-content {
    color: #FFFFFF;
  }
}

html[data-theme="light"] {
  .pro-sidebar-inner {
    background: #F5F7FA;
  }

  .pro-item-content {
    color: #405D80;
  }
}

html[dir=rtl] {

  .pro-item-content {
    font-weight: 700;
    font-size: 18px;
  }
}

.pro-sidebar .pro-inner-item {
  padding: 8px 35px 8px 30px !important;
}

@import '~react-pro-sidebar/dist/scss/styles.scss';
.main-container {
    width: 90%;
    height: max-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin: 5% 0%;
}

.middle-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    margin: 0% 0% 10% 0%;
}

.middle-container .left {
    flex: 1;
}

.middle-container .right {
    flex: 1;
}

.calendar-container {
    min-height: 70vh;
    min-width: 100%;
    padding: 1rem;
    border: 1px solid #222222;
    border-radius: 1rem;
    background: rgb(25, 25, 25);
    background: linear-gradient(
        90deg,
        rgba(25, 25, 25, 1) 27%,
        rgba(36, 36, 36, 1) 97%
    );
    opacity: 0.8;
}

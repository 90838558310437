.main-booking-container {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 0.5rem;
    border: 1px solid white;
    border-radius: 1rem;
    padding: 1rem;
}
.main-booking {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}
.booking-line {
    height: inherit;
    border-image: linear-gradient(269.85deg, #c4739a 3.22%, #2a69a5 100.77%);
    border-image-slice: 1;
    border-width: 2px 2px 2px 2px;
    border-style: solid;
    margin-right: 5%;
}
ul {
    list-style: none;
}

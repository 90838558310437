.adjust {
    /* display: flex; */
}
.slots-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 30vh;
    gap: 1rem 0rem;
    flex-wrap: wrap;
    margin-top: 5%;
    width: 100%;
}
.slots-container > Button {
    margin: 0px;
}
.disabled {
    color: #353535 !important;
}

.disabled:hover {
    cursor: auto;
}

.event {
    background: linear-gradient(90deg, #016299 0%, #f77599 92.59%) !important;
}

.date-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10%;
}

.single-date {
    margin-top: 3%;
    background: linear-gradient(90deg, #016299 0%, #f77599 92.59%);
    border: 3px;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0rem 20px;
}

hr {
    height: 2px;
    background-color: white;
}

/* transition for smooth animations */
html.theme-transition,
html.theme-transition *,
html.theme-transition *:before,
html.theme-transition *:after {
  transition: all 750ms !important;
  transition-delay: 0 !important;
}

/* styling for the react toastify component */
:root {
  --toastify-toast-width: fit-content !important;
}

/* input autofill background styling */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: black !important;
}

* {
  box-sizing: border-box;
}

html[data-theme="light"] *::placeholder {
  color: #bdbdbd;
}

html[data-theme="dark"] *::placeholder {
  color: #c7c7c7;
}

html[data-theme="light"] input {
  color: #000000;
}

html[data-theme="dark"] input {
  color: #ffffff;
}

/*Animated Guide Styling*/
.introjs-tooltip {
  border-radius: 13px !important;
  background-color: #101010b3 !important;
  color: #fff;
  border-color: #000 !important;
}
.introjs-button {
  background: linear-gradient(90deg, #016299 0%, #f77599 92.59%) !important;
  border-radius: 10px !important;
  padding: 10px 25px !important;
  outline: none !important;
  border: none !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #ffffff !important;
  text-align: center !important;
  cursor: pointer !important;
  width: max-content !important;
}

.main-search-container {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-top: 0.5rem;
    margin-bottom: 0px;
}
.search-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
}
.search-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

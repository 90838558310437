.invalid {
    border: 1px solid red;
    background: none;
    color: white;
    border-radius: 5px;
    font-weight: 300;
    width: 318px;
    padding: 8px;
    font-size: 20px;
    position: relative;
    overflow: hidden;
}

.valid {
    border: 1px solid white;
    background: none;
    color: white;
    border-radius: 5px;
    font-weight: 300;
    width: 318px;
    padding: 8px;
    font-size: 20px;
    position: relative;
    overflow: hidden;
}

.collab {
    width: 90%;
}

.main-search-container {
    display: flex;
    flex-direction: column;
    align-self: start;
    margin-top: 0.5rem;
    margin-bottom: 0px;
}
.search-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
}
.search-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dropdown-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #f8f8f8;
    border: 1px solid #1a1a1a;
}

.dropdown-list:hover {
    cursor: pointer;
}

.dropdown-item h2 {
    color: #717272;
    margin: 1rem;
}

.dark-theme {
    background-color: #1a1a1a;
    border: 1px solid #737b8f;
}

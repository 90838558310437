html[dir="ltr"] .signin-Btn {
  margin-right: 3%;
}

html[dir="rtl"] .signin-Btn {
  margin-left: 3%;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-toggle {
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn:focus {
  color: transparent !important;
  box-shadow: unset !important;
}

.btn {
  border: none !important;
}
.modal-content {
  background-color: #fff !important;
}

.customBackground {
  background-color: #000 !important;
  padding: 0 !important;
  border-radius: 30px;
}
